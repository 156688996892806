<template>
  <div
    class="white unselect overflow-hidden d-flex flex-column align-center"
    style="width:100vw;height:100vh;"
  >
    <div style="margin-top:20vh">
      <v-icon color="#10AEFF" size="120">mdi-information</v-icon>
    </div>
    <div class="text-h6 mt-6">{{ errorInfo }}</div>
  </div>
</template>

<script>
let errorPrompts = {
  SessionIdExpired: "二维码已过期，请刷新页面后重新扫码"
};
export default {
  name: "ErrorMobilePrompt",
  data() {
    return {
      errorInfo: null,
      errorPrompts
    };
  },
  created() {
    let type = this.$route.query.type;
    if (type) {
      this.errorInfo = this.errorPrompts[type];
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.v-input__slot {
  padding: 6px 12px !important;
  min-height: 48px !important;
}

/deep/.v-input__append-inner {
  margin-top: 8px !important;
}

/deep/.v-label {
  top: 10px !important;
}
</style>
